import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAccessTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/access-type', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaces() {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace', { params: { perPage: 99999999 } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution/user', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/institution/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/institution/user', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/institution/user/${userData.id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    activateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/institution/user/${userData.id}/activate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    inactivateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/institution/user/${userData.id}/inactivate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
